<template>
	<div class="page">
		<el-main>
			<el-container>
				<el-row>
					<el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form :inline="true">
							<el-form-item>
								<el-select v-model="checkUfaceConfig" multiple filterable collapse-tags placeholder="请选择配置" style="width: 220px" @change="SearchPage()" size="medium">
									<el-option v-for="(item, i) in checkUfaceConfigData" :key="i" :label="item.ConfigName" :value="item.AppID"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-select v-model="personType" placeholder="请选择人员类型" style="width: 100px" @change="personChange" size="medium">
									<el-option v-for="(item, i) in personTypeData" :key="i" :label="item.title" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item v-show="personType == 0">
								<el-select v-model="checkClass" multiple filterable collapse-tags placeholder="班级" style="width: 220px" @change="SearchPage()" size="medium">
									<el-option v-for="(item, i) in checkClassData" :key="i" :label="item.ClassName" :value="item.Id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item v-show="personType == 0">
								<el-select v-model="checkStudentType" multiple filterable collapse-tags placeholder="学生类型" style="width: 180px" @change="SearchPage()" size="medium">
									<el-option v-for="(item, i) in checkStudentTypeData" :key="i" :label="item.title" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width: 300px; margin-right: 10px" size="medium">
								<template #prepend>
									<el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 120px">
										<el-option v-for="(item, i) in dataTableSearch" :key="i" :label="item.label" :value="item.attr"></el-option>
									</el-select>
								</template>
							</el-input>
							<slot name="searchSlot"></slot>
							<el-form-item>
								<el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage()">查询</el-button>
								<el-button type="success" icon="el-icon-key" size="medium" @click="personAuth()">授权</el-button>
								<el-button type="danger" v-has="'FakeDelete'" icon="el-icon-delete" size="medium" @click="DeletePerson()">删除</el-button>
							</el-form-item>
							<el-form-item>
								<el-popover placement="bottom" :width="400" trigger="click">
									<template #reference>
										<el-button size="medium"><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
									</template>
									<el-checkbox-group v-model="dataTableSearchList">
										<div v-for="(item, i) in dataTableList" :key="i">
											<el-checkbox v-if="item.visible != false" :label="item" checked style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
											<el-checkbox v-else :label="item" style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
										</div>
									</el-checkbox-group>
									<el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
									<el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
								</el-popover>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-table size="small" border ref="multipleTable" :data="dataTable" style="width: 99%" max-height="700" :height="tableHeight" :fit="true" @selection-change="handleSelectionChange">
							<el-table-column type="selection" fixed="left" header-align="center" align="center"></el-table-column>
							<el-table-column label="操作" fixed="right" header-align="center" align="center" width="100">
								<template #default="scope">
									<el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="personDelete(scope.row.Id)">
										<template #reference>
											<el-button type="danger" v-has="'FakeDelete'" size="mini" plain>删除</el-button>
										</template>
									</el-popconfirm>
								</template>
							</el-table-column>
							<el-table-column v-for="(col, index) in dataTableSearchList" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
								<template #default="scope">
									<el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
									<el-tag size="medium" v-else-if="col.type == 'enum'">
										{{ col.formatters(scope.row[col.attr]) }}
									</el-tag>

									<el-image v-else-if="col.type == 'custom'" style="width: 50px; height: 50px; margin-left: 5px" v-for="ite in scope.row[col.attr]" :key="ite" :src="ite.FaceUrl" :preview-src-list="[ite.FaceUrl]" lazy></el-image>

									<label v-else>{{ scope.row[col.attr] }}</label>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<paging :currentPage="currentPage" :pagesize="pageSize" :total="total" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange" :pagesizes="pageSizes" />
					</el-col>
				</el-row>
			</el-container>
		</el-main>
		<el-dialog v-model="dialogVisible" :title="dialogTitle" width="30%" :before-close="handleClose" destroy-on-close center>
			<template #title>
				<h1>人员授权</h1>
				<div style="text-align: left; font-size: initial; line-height: initial">
					注：授权<font style="color: #f56c6c">{{ authText }}</font
					>信息
				</div>
			</template>
			<el-form label-position="left" label-width="100px">
				<el-form-item label="授权组">
					<el-select v-model="groupGuid" placeholder="请选择授权组" style="width: 250px">
						<el-option v-for="(item, i) in groupData" :key="i" :label="item.GroupName" :value="item.GroupGuid"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="允许通行">
					<div v-for="(item, index) in passTimes" :key="index" style="margin-top: 4px">
						<span style="font-size: 14px">时段{{ index + 1 }}&nbsp;</span>
						<el-time-select placeholder="起始时间" v-model="item.startTime" start="00:00" step="00:05" end="23:59" class="stime"> </el-time-select>至
						<el-time-select placeholder="结束时间" v-model="item.endTime" start="00:00" step="00:05" end="23:59" :minTime="item.startTime" class="stime"> </el-time-select>
						<el-button type="text" plain v-if="index == 0" icon="el-icon-circle-plus" @click="addTime()"></el-button>
						<el-button type="text" plain v-else icon="el-icon-remove" @click="removeTime(index)"></el-button>
					</div>
					<div class="el-form-item-msg" style="line-height: 10px">
						注：不设置通行时段默认全天通行！
					</div>
				</el-form-item>
				<el-form-item label="有效期">
					<el-date-picker v-model="permissionTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY 年 MM 月 DD 日" value-format="x"> </el-date-picker>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="personAuthSubmit()">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
let _this;
export default {
	name: "UfacePerson",
	components: {},
	data() {
		return {
			dialogTitle: "人员授权",
			dialogVisible: false, //人员授权
			checkUfaceConfig: "",
			checkUfaceConfigData: [],
			personType: 0,
			personTypeData: [
				{ title: "学生", value: 0 },
				{ title: "教职工", value: 1 },
			],
			checkStudentType: [],
			checkStudentTypeData: [
				{ title: "走读生", value: 0 },
				{ title: "半走读", value: 1 },
				{ title: "住校生", value: 2 },
			],
			checkClass: [],
			checkClassData: [],
			total: 0, //数据总条数
			pageSize: 10, //当前显示数据数量
			pageSizes: [10, 20, 50, 100, 200, 500, 1000, 2000],
			currentPage: 1, //分页当前页
			keyword: "", //搜索输入关键字
			checkInput: "", //选择所需要查询的内容
			dataConfig: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
					isDisplay: false,
				},
				{
					label: "人员姓名",
					attr: "PersonName",
					isSearch: true,
				},
				{
					label: "识别照片",
					attr: "FaceData",
					type: "custom",
				},
				{
					label: "人员类型",
					attr: "PersonType",
					type: "enum",
					formatters: function(val) {
						var result;
						switch (val) {
							case 0:
								result = "学生";
								break;
							case 1:
								result = "教职工";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{
					label: "标签",
					attr: "Tag",
					isSearch: true,
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					isSearch: true,
					visible: false,
				},
				{
					label: "身份证号",
					attr: "IdCardNo",
					visible: false,
				},
				{
					label: "电话",
					attr: "Phone",
					visible: false,
				},
				{
					label: "卡号",
					attr: "CardNo",
					visible: false,
				},
				{
					label: "人员Guid",
					attr: "PersonGuid",
					visible: false,
				},
				{
					label: "创建时间",
					attr: "CreatedTime",
					visible: false,
				},
				{
					label: "更新时间",
					attr: "UpdatedTime",
				},
			],
			dataTableSearch: [], //搜索信息
			dataTableList: [], //显示列信息
			dataTableSearchList: [], //列筛选信息
			dataTable: [], //显示数据
			multipleSelection: [], //选中数据
			tableHeight: 200, //table高度
			groupData: [],
			groupGuid: "",
			passTimes: [
				{
					startTime: "",
					endTime: "",
				},
			],
			permissionTime: "",
			personGuids: [],
			authText: "",
		};
	},
	created() {
		_this = this;
	},
	mounted() {
		_this.$nextTick(function() {
			// 仅在整个视图都被渲染之后才会运行的代码
			//设置table位置
			let heightStyle = window.innerHeight - 230;
			_this.tableHeight = heightStyle;

			//窗口大小改变时，table 高度设置
			window.onresize = () => {
				return (() => {
					let heightStyle = window.innerHeight - 230;
					_this.tableHeight = heightStyle;
				})();
			};
		});
		_this.initCheckUfaceConfigDataList();
		_this.initCheckClassList();
		_this.SearchPage();
		_this.dataTableSearch = _this.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
		_this.dataTableList = _this.dataConfig.filter((it) => it.isDisplay != false); //可显示列
	},
	methods: {
		//初始化配置选择数据
		initCheckUfaceConfigDataList() {
			_this.$API.UfaceConfig.GetUfaceConfigList.get().then((res) => {
				_this.checkUfaceConfigData = res;
			});
		},
		//初始化班级选择数据
		initCheckClassList() {
			_this.$API.Classes.GetClassesDataList.get().then((res) => {
				_this.checkClassData = res;
			});
		},
		personChange() {
			if (_this.personType == 1) {
				_this.checkClass = [];
				_this.checkStudentType = [];
			}
			_this.SearchPage();
		},
		//页面搜索查询
		SearchPage() {
			let json = {
				searchValue: "",
				pageNo: _this.currentPage,
				pageSize: _this.pageSize,
				searchBeginTime: _this.searchStartTime,
				searchEndTime: _this.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
				AppIds: _this.checkUfaceConfig,
				ClassIds: _this.checkClass,
				PersonType: _this.personType,
				StudentTypes: _this.checkStudentType,
			};
			if (_this.checkInput.length > 0 && _this.keyword != "") {
				var searchParameters = [],
					input = _this.keyword;
				for (let index = 0; index < _this.checkInput.length; index++) {
					const element = _this.checkInput[index];
					var searchParametersItem = {
						field: element,
						op: 8,
						value: input,
						orGroup: "",
					};
					searchParameters.push(searchParametersItem);
				}
				json.searchParameters = searchParameters;
			}

			_this.$API.UfacePerson.QueryByPage.post(json).then((res) => {
				if (res) {
					_this.total = res.TotalRows;
					_this.pagesize = res.PageSize;
					_this.currentPage = res.PageNo;
					_this.dataTable = res.Rows;
				}
			});
		},
		handleSizeChange(val) {
			_this.pageSize = val;
			_this.SearchPage();
		},
		handleCurrentChange(val) {
			_this.currentPage = val;
			_this.SearchPage();
		},
		handleSelectionChange(val) {
			_this.multipleSelection = val;
		},
		// 列筛选
		filterFunHandle(type, currentItem, index) {
			if (type === "allchecked") {
				// 全选
				_this.dataTableSearchList = _this.dataConfig.filter((it) => it.isDisplay != false);
			} else if (type === "cancel") {
				// 取消全选
				_this.dataTableSearchList = []; // 复选框置为空，全部不选择
			}
		},
		DeletePerson() {
			if (!_this.multipleSelection || _this.multipleSelection.length <= 0) {
				_this.$message.warning("请选择需要删除的人员！");
				return;
			}
			var ids = new Array();
			var msgStr = "";
			_this.multipleSelection.forEach((it) => {
				ids.push(it.Id);
				msgStr += `【${it.PersonName}】`;
			});
			_this
				.$confirm(`确认删除${msgStr}等${_this.multipleSelection.length}条人员信息吗？`)
				.then(() => {
					_this.$API.UfacePerson.FakeDelete.delete(ids).then((res) => {
						if (res.Success) {
							_this.$message.success(res.Message);
							_this.SearchPage();
						}
					});
				})
				.catch(() => {});
		},
		personDelete(id) {
			_this.$API.UfacePerson.FakeDelete.delete([id]).then((res) => {
				if (res.Success) {
					_this.$message.success(res.Message);
					_this.SearchPage();
				}
			});
		},
		operationSuccess() {
			//操作成功后，关闭弹出框，刷新数据显示
			_this.dialogVisible = false;
			_this.SearchPage();
		},
		handleClose(done) {
			_this
				.$confirm("确认关闭？")
				.then(() => {
					done();
				})
				.catch(() => {});
		},
		personAuth() {
			if (_this.dataTable.length <= 0) {
				_this.$message.warning("无人员信息可授权，请核实人员信息是否同步!");
				return;
			}
			_this.$API.UfacePerson.GetUfaceGroups.get().then((res) => {
				if (res && res.length > 0) {
					_this.groupData = res;
					_this.groupGuid = "";
					_this.personGuids = [];
					_this.authText = "";
					var msgStr = "";
					var msgTxt = "";
					if (_this.personType == 0) {
						//学生
						if (_this.multipleSelection && _this.multipleSelection.length > 0) {
							_this.multipleSelection.forEach((it) => {
								_this.personGuids.push(it.PersonGuid);
								msgStr += `【${it.PersonName}】`;
							});
							msgTxt = `学生人员${msgStr}等${_this.multipleSelection.length}人`;
						} else {
							if (_this.checkClass && _this.checkClass.length > 0) {
								_this.checkClass.forEach((it) => {
									var data = _this.checkClassData.filter((i) => i.Id == it)[0];
									if (data) {
										msgStr += `【${data.ClassName}】`;
									}
								});
								msgTxt = `班级${msgStr}等${_this.checkClass.length}个班的`;
							} else {
								msgTxt = `全校的`;
							}
							if (_this.checkStudentType && _this.checkStudentType.length > 0) {
								var str = "";
								_this.checkStudentType.forEach((it) => {
									var data = _this.checkStudentTypeData.filter((i) => i.value == it)[0];
									if (data) {
										str += `【${data.title}】`;
									}
								});
								msgTxt += str + "人员";
							} else {
								msgTxt += "学生人员";
							}
						}
					} else {
						//教职工
						if (_this.multipleSelection && _this.multipleSelection.length > 0) {
							_this.multipleSelection.forEach((it) => {
								_this.personGuids.push(it.PersonGuid);
								msgStr += `【${it.PersonName}】`;
							});
							msgTxt = `教职工人员${msgStr}等${_this.multipleSelection.length}人`;
						} else {
							msgTxt = `全校的教职工人员`;
						}
					}
					_this.authText = msgTxt;
					_this.dialogVisible = true;
				} else {
					_this.$message.warning("未获取到授权组信息!");
				}
			});
		},
		addTime() {
			if (_this.passTimes.length < 6) {
				_this.passTimes.push({
					startTime: "",
					endTime: "",
				});
			} else {
				_this.$message.warning("最多设置6个时段!");
			}
		},
		removeTime(index) {
			_this.passTimes.splice(index, 1);
		},
		personAuthSubmit() {
			if (!_this.groupGuid) {
				_this.$message.warning("请选择授权组!");
				return;
			}
			var passTime = "";
			if (_this.passTimes.length > 0) {
				_this.passTimes.forEach((it) => {
					if (it.startTime != "" && it.endTime != "") {
						passTime += `${it.startTime}:00,${it.endTime}:00,`;
					}
				});
				passTime = passTime.substring(0, passTime.length - 1).replace(" ", "");
			}
			var permissiondate = "";
			if (_this.permissionTime.length == 2) {
				permissiondate = `${_this.permissionTime[0]},${_this.permissionTime[1]}`;
			}
			_this
				.$confirm(`确认授权${_this.authText}信息到授权组【${_this.groupData.filter((i) => i.GroupGuid == _this.groupGuid)[0].GroupName}】吗？`)
				.then(() => {
					var json = {
						GroupGuid: _this.groupGuid,
						PersonGuids: _this.personGuids,
						StudentTypes: _this.checkStudentType,
						ClassIds: _this.checkClass,
						PersonType: _this.personType,
						PassTime: passTime,
						PermissionTime: permissiondate,
					};
					_this.$message.success("开始授权");
					_this.dialogVisible = false;
					// const loading = _this.$loading({
					//   lock: true,
					//   text: "数据处理中",
					//   spinner: "el-icon-loading",
					//   background: "rgba(0, 0, 0, 0.7)",
					// });
					_this.$API.UfacePerson.UfaceGroupPersonAuth.post(json).then((res) => {
						// loading.close();
						_this.dialogVisible = false;
						if (res.Success) {
							_this.$message.success(res.Message);
						} else {
							_this.$message.error(res.Message);
						}
					});
				})
				.catch(() => {});
		},
	},
};
</script>
<style scoped>
.page {
	margin: 5px;
	padding: 20px;
	min-height: 840px;
	width: 100%;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
	width: auto;
}
.drawerTitle {
	color: #409eff;
}
.stime {
	width: 120px;
}
</style>
